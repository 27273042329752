// Disable some linters as print styles need to be more restrictive on some places
// sass-lint:disable no-important, no-ids, id-name-format

// Use this file to define print styles.
// Import this file using the following HTML or equivalent:
// <link href="/stylesheets/print.css" media="print">

// NOTE: also look into fountadions _print.scss for the baseline this styles build on

@charset 'UTF-8';

// ///////////////////////////////////////////
// custom print styles
@media print {
    // -------------------------------------
    // style print pages itself
    @page {
        margin: 2em;
        size: A4 portrait;
    }

    // -------------------------------------
    // html/body resets
    html,
    body {
        height: auto !important;
    }

    // -------------------------------------
    // prevent tags messing up page breaks
    * {
        position: static !important;
        float: none !important;
    }

    .off-canvas {
        height: auto !important;
        min-height: 0 !important;
    }

    // -------------------------------------
    // better table design for print
    table {
        table-layout: fixed;
    }

    // -------------------------------------
    // HIDE ALL THE THINGS
    script,
    style,
    title,
    .browser-alert, // browser alert boxes
    div.pswp, // PhotoSwipe container
    #wpadminbar, // WordPress adminbar
    .header-spacing,
    .menu-toggle,
    .off-canvas,
    .scroll-up,
    .logo::after {
        display: none !important;
    }
}
